import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
// Components
import { Link, graphql } from "gatsby"

const Tags = ({ pageContext, data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Study site`
  const { tag } = pageContext
  const { totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } are tagged with "${tag}"`
  const title = `posts with ${tag} tag`
  const description = `${totalCount} posts with ${tag} tag on ${siteTitle}`
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle} bigHeading="true">
      <Seo
        title={title}
        description={description}
        pathname={`/tags/${kebabCase(tag)}/`}
      />
      {/* <Bio /> */}
      <div>
        <h1>{tagHeader}</h1>
        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const postTitle =
              post.node.frontmatter.title || post.node.fields.slug

            return (
              <li key={post.node.fields.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2>
                      <Link to={post.node.fields.slug} itemProp="url">
                        <span itemProp="headline">{postTitle}</span>
                      </Link>
                    </h2>
                    <small>{post.node.frontmatter.updated}</small>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          post.node.frontmatter.description ||
                          post.node.excerpt,
                      }}
                      itemProp="description"
                    />
                  </section>
                </article>
              </li>
            )
          })}
        </ol>
        <Link to="/tags/"> &#8592; All tags</Link>
      </div>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query ($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___updated], order: DESC }
      filter: { frontmatter: { draft: { ne: true }, tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            updated(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
